// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyCEb7mD9VFlLoPooi2QAiS7et_c_d05R70',
    authDomain: 'demopred-218f9.firebaseapp.com',
    databaseURL: 'https://demopred-218f9-default-rtdb.firebaseio.com',
    projectId: 'demopred-218f9',
    storageBucket: 'demopred-218f9.appspot.com',
    messagingSenderId: '839134278072',
    appId: '1:839134278072:web:06179622378676808fdf4d',
    measurementId: 'G-XFB1P8E6FJ'
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
